var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-list",
    {
      attrs: { expanded: _vm.expanded },
      on: {
        toggleExpanded: function() {
          return (_vm.expanded = !_vm.expanded)
        }
      }
    },
    [
      _c("template", { slot: "header-left" }, [
        _c("span", { staticClass: "body font-bold" }, [
          _vm._v(_vm._s(_vm.$t("List items")))
        ])
      ]),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "news-list" },
          [
            _c(
              "draggable",
              {
                on: { end: _vm.dragPagesEnd },
                model: {
                  value: _vm.newPages,
                  callback: function($$v) {
                    _vm.newPages = $$v
                  },
                  expression: "newPages"
                }
              },
              _vm._l(_vm.newPages, function(page) {
                return _c("div", { key: page.id, staticClass: "news-item" }, [
                  _c("div", { staticClass: "news-item__left" }, [
                    _c("img", {
                      staticClass: "news-item__left--img",
                      attrs: {
                        src:
                          page.thumbnail && page.thumbnail.url
                            ? page.thumbnail.url
                            : require("@/assets/gray-background.png"),
                        alt: "image"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "news-item__left--info" },
                      [
                        page.project && !_vm.isArchive(page)
                          ? _c(
                              "router-link",
                              {
                                staticClass: "mb-none title",
                                attrs: {
                                  target: "_blank",
                                  to: _vm.linkTo(page)
                                }
                              },
                              [_vm._v(" " + _vm._s(page.name) + " ")]
                            )
                          : _c("div", { staticClass: "title" }, [
                              _vm._v(" " + _vm._s(page.name) + " "),
                              _vm.isArchive(page)
                                ? _c("span", { staticClass: "noticed" }, [
                                    _vm._v(
                                      " ★" +
                                        _vm._s(
                                          _vm.$t("This is an archived page")
                                        ) +
                                        " "
                                    )
                                  ])
                                : _vm._e()
                            ]),
                        _c("div", { staticClass: "des" }, [
                          _vm._v(_vm._s(page.description))
                        ])
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "news-item__right" },
                    [
                      _c("a-button", {
                        attrs: { icon: "edit" },
                        on: {
                          click: function() {
                            return _vm.$emit("editPage", page)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.sourcePageStatus(page) === false
                        ? _c(
                            "a-tooltip",
                            {
                              attrs: {
                                title: _vm.$t(
                                  "The source page is currently private!"
                                )
                              }
                            },
                            [
                              _c("a-button", {
                                staticStyle: {
                                  "border-color": "#f55454",
                                  color: "#f55454"
                                },
                                attrs: { disabled: "", icon: "eye-invisible" }
                              })
                            ],
                            1
                          )
                        : _vm.pageStatus(page)
                        ? _c("a-button", {
                            staticStyle: {
                              "border-color": "#04AA6D",
                              color: "green"
                            },
                            attrs: { icon: "eye" },
                            on: {
                              click: function($event) {
                                return _vm.handleChangeStatus(page)
                              }
                            }
                          })
                        : _c("a-button", {
                            staticStyle: {
                              "border-color": "#f44336",
                              color: "red"
                            },
                            attrs: { icon: "eye-invisible" },
                            on: {
                              click: function($event) {
                                return _vm.handleChangeStatus(page)
                              }
                            }
                          }),
                      _vm._v(" "),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            placement: "topLeft",
                            "ok-text": _vm.$t("Yes"),
                            "cancel-text": _vm.$t("No"),
                            title: _vm.$t("Are you sure to delete this site?")
                          },
                          on: {
                            confirm: function() {
                              return _vm.deletePage(page)
                            }
                          }
                        },
                        [
                          _c("a-button", {
                            attrs: { icon: "delete", loading: _vm.loading }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              }),
              0
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }