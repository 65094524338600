var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project.data.length
    ? _c(
        "section-list",
        { attrs: { allowCollapseExpand: false } },
        [
          _c("template", { slot: "header-left" }),
          _c("template", { slot: "body" }, [
            _c(
              "div",
              [
                _c(
                  "a-spin",
                  { attrs: { spinning: _vm.project.loading } },
                  [
                    _c(
                      "a-collapse",
                      {
                        staticStyle: {
                          "text-align": "left",
                          "font-size": "18px"
                        },
                        attrs: {
                          accordion: "",
                          bordered: false,
                          "default-active-key": _vm.project.data[0].id
                        },
                        on: { change: _vm.handleOnOpenProjectCollapse },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "expandIcon",
                              fn: function(props) {
                                return [
                                  _c("a-icon", {
                                    attrs: {
                                      type: "caret-right",
                                      rotate: props.isActive ? 90 : 0
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          766930589
                        )
                      },
                      _vm._l(_vm.project.data, function(project) {
                        return _c(
                          "a-collapse-panel",
                          { key: project.id, attrs: { "show-arrow": "" } },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("div", { staticClass: "title" }, [
                                  _vm._v(_vm._s(project.name))
                                ]),
                                _c("span", { staticClass: "sub-body" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "description",
                                      attrs: {
                                        href:
                                          "https://" +
                                          project.sub_domain +
                                          _vm.baseDomain,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            "https://" +
                                              project.sub_domain +
                                              _vm.baseDomain
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "page-list pb-lg mb-sm" },
                              [
                                _c("search-box", {
                                  staticClass: "pb-sm",
                                  attrs: { queryParam: _vm.page.search },
                                  on: {
                                    changeTitle: function(value) {
                                      return (_vm.page.search.title = value)
                                    },
                                    changeSlug: function(value) {
                                      return (_vm.page.search.slug = value)
                                    },
                                    handleSearch: _vm.handleSearchPage,
                                    handleReset: _vm.handleResetPage
                                  }
                                }),
                                _c("SGrid", {
                                  attrs: {
                                    "grid-name": "page-mapping-portal",
                                    gridHeight: 500,
                                    gridContext: _vm.context,
                                    rowData: _vm.page.data,
                                    columnDefs: _vm.columnDefs,
                                    pagination: _vm.page.pagination,
                                    rowHeight: 120,
                                    hideOnSinglePage: false
                                  },
                                  on: { onPageChange: _vm.handlePageChange }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }