//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";

export default Vue.extend({
  computed: {
    data() {
      return this.params.data;
    },
    configPassing() {
      const config = this.params.getValue();
      return config;
    },
    loading() {
      return this.configPassing?.button?.add?.loading;
    },
    confirmTitleHtml() {
      let html = `<div>${this.$t("Are you sure you want to add this page to the portal?")}</div>`;
      if (!this.data.ispublic) {
        html =
          html +
          `<div class="caption">${this.$t(
            "This page is not public, so that it can not be shown in the portal after picking up"
          )}</div>`;
      }
      return html;
    }
  },
  methods: {
    handleAddPage() {
      this.params.context.componentParent.handleAddPage(this.data);
    }
  }
});
