//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    expanded: { type: Boolean, default: true },
    allowCollapseExpand: { type: Boolean, default: true }
  }
}
