var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-list",
    {
      attrs: {
        expanded: _vm.expanded,
        allowCollapseExpand: _vm.newPages.length > 0
      },
      on: {
        toggleExpanded: function() {
          return (_vm.expanded = !_vm.expanded)
        }
      }
    },
    [
      _c("template", { slot: "header-left" }, [
        _c("span", { staticClass: "body font-bold" }, [
          _vm._v(" " + _vm._s(_vm.category.name) + " ")
        ])
      ]),
      _c(
        "template",
        { slot: "header-right" },
        [
          _c("a-button", {
            attrs: { icon: "edit" },
            on: {
              click: function() {
                return _vm.$emit("openCategoryEdit")
              }
            }
          }),
          _vm._v(" "),
          _c(
            "a-popconfirm",
            {
              attrs: {
                placement: "topLeft",
                "ok-text": _vm.$t("Yes"),
                "cancel-text": _vm.$t("No"),
                title: _vm.$t("Do you want to delete this Category?")
              },
              on: { confirm: _vm.deleteCategory }
            },
            [
              _c("a-button", {
                attrs: { icon: "delete", loading: _vm.loading }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "page-list" },
          [
            _c(
              "draggable",
              {
                on: { end: _vm.dragPagesEnd },
                model: {
                  value: _vm.newPages,
                  callback: function($$v) {
                    _vm.newPages = $$v
                  },
                  expression: "newPages"
                }
              },
              _vm._l(_vm.newPages, function(page) {
                return _c(
                  "div",
                  {
                    key: _vm.category.id + "-" + page.id,
                    staticClass: "page-item"
                  },
                  [
                    _c("div", { staticClass: "page-item__left" }, [
                      _c("div", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            src:
                              page.thumbnail && page.thumbnail.url
                                ? page.thumbnail.url
                                : require("@/assets/gray-background.png"),
                            alt: "image"
                          }
                        })
                      ]),
                      _c(
                        "div",
                        { staticClass: "info" },
                        [
                          page.project && !_vm.isArchive(page)
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "mb-none info__title",
                                  attrs: {
                                    target: "_blank",
                                    to: _vm.linkTo(page)
                                  }
                                },
                                [_vm._v(" " + _vm._s(page.name) + " ")]
                              )
                            : _c("div", { staticClass: "info__title" }, [
                                _vm._v(" " + _vm._s(page.name) + " "),
                                _vm.isArchive(page)
                                  ? _c(
                                      "span",
                                      { staticClass: "info__noticed" },
                                      [
                                        _vm._v(
                                          " ★" +
                                            _vm._s(
                                              _vm.$t("This is an archived page")
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]),
                          _c("div", { staticClass: "info__des" }, [
                            _vm._v(" " + _vm._s(page.description) + " ")
                          ])
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "page-item__right" },
                      [
                        _vm.categoriesOptions.length > 0
                          ? _c(
                              "a-dropdown",
                              [
                                _c(
                                  "a-menu",
                                  {
                                    attrs: { slot: "overlay" },
                                    on: { click: _vm.movePageToCategory },
                                    slot: "overlay"
                                  },
                                  _vm._l(_vm.categoriesOptions, function(item) {
                                    return _c(
                                      "a-menu-item",
                                      { key: page.id + "|" + item.id },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  }),
                                  1
                                ),
                                _c(
                                  "a-button",
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Move to category")))
                                    ]),
                                    _c("a-icon", { attrs: { type: "down" } })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.sourcePageStatus(page) === false
                          ? _c(
                              "a-tooltip",
                              {
                                attrs: {
                                  title: _vm.$t(
                                    "The source page is currently private!"
                                  )
                                }
                              },
                              [
                                _c("a-button", {
                                  staticStyle: {
                                    "border-color": "#f55454",
                                    color: "#f55454"
                                  },
                                  attrs: { disabled: "", icon: "eye-invisible" }
                                })
                              ],
                              1
                            )
                          : _vm.pageStatus(page)
                          ? _c("a-button", {
                              staticStyle: {
                                "border-color": "#04AA6D",
                                color: "green"
                              },
                              attrs: { icon: "eye" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeStatus(page)
                                }
                              }
                            })
                          : _c("a-button", {
                              staticStyle: {
                                "border-color": "#f44336",
                                color: "red"
                              },
                              attrs: { icon: "eye-invisible" },
                              on: {
                                click: function($event) {
                                  return _vm.handleChangeStatus(page)
                                }
                              }
                            })
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }