//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionGroup from '@/components/PortalSite/SectionGroup.vue';
import TextEditor from '@/components/tools/TextEditor.vue';

export default {
  components: { SectionGroup, TextEditor },

  props: {
    portalSite: { type: Object, default: () => ({}) },
    settingOptions: { type: Object, default: () => ({}) },
    iframeCode: { type: String, default: '' },
    draftIframeCode: { type: String, default: '' },
    viewMode: { type: Array, default: () => [] }
  },

  data() {
    return {
      showHeader: true,
      showFooter: true,
      isEditingHeader: false,
      pageLang: 'jpn',
      headerContent: '',
      footerContent: '',
      isEditingFooter: false,
      expanded: this.$ls.get('uv-exp-preview-section') ?? true,
      previewId: 'DRAFT'
    };
  },

  watch: {
    settingOptions: {
      handler() {
        this.initSettingOptions(this.settingOptions);
      },
      immediate: true
    }
  },

  computed: {
    portalSiteId() {
      return this.portalSite?.id;
    },
    isDraftPreview() {
      return this.previewId === 'DRAFT';
    },
    isPublic() {
      return this.portalSite?.ispublic;
    }
  },

  methods: {
    initSettingOptions(data) {
      const { show_header, show_footer, language, header, footer } = data;
      this.showHeader = show_header ?? true;
      this.showFooter = show_footer ?? true;
      this.pageLang = language || 'jpn';
      this.headerContent = header || '';
      this.footerContent = footer || '';
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-preview-section', this.expanded);
    },
    changeModeIframe({ key }) {
      const mode = this.viewMode.find(elm => elm.value === key);
      const newIframeCode = this.iframeCode
        .replace(/(height=)[0-9]*((px)|%)/g, `height=${mode.height}`)
        .replace(/(width=)[0-9]*((px)|%)/g, `width=${mode.width}`);
      this.$emit('change-iframe-code', newIframeCode);
      // draftIframeCode
      const newDraftIframeCode = this.draftIframeCode
        .replace(/(height=)[0-9]*((px)|%)/g, `height=${mode.height}`)
        .replace(/(width=)[0-9]*((px)|%)/g, `width=${mode.width}`);
      this.$emit('change-draft-iframe-code', newDraftIframeCode);
    },
    openHeaderEditor() {
      this.isEditingHeader = true;
    },
    closeHeaderEditor() {
      this.isEditingHeader = false;
    },
    changeHeaderContent(value) {
      this.headerContent = value;
    },
    changeFooterContent(value) {
      this.footerContent = value;
    },
    openFooterEditor() {
      this.isEditingFooter = true;
    },
    closeFooterEditor() {
      this.isEditingFooter = false;
    },
    updateHeaderContent() {
      let div = document.createElement('div');
      div.innerHTML = this.headerContent;
      let images = div.getElementsByTagName('img');
      let files = [];
      for (let i = 0; i < images.length; i++) {
        let imgSrc = images[i] ? images[i].src : '';
        if (imgSrc)
          files.push(
            decodeURI(
              imgSrc
                .split('\\')
                .pop()
                .split('/')
                .pop()
            )
          );
      }
      this.updateSettingOptions({ header: this.headerContent });
      this.isEditingHeader = false;
    },
    updateFooterContent() {
      let div = document.createElement('div');
      div.innerHTML = this.footerContent;
      let images = div.getElementsByTagName('img');
      let files = [];
      for (let i = 0; i < images.length; i++) {
        let imgSrc = images[i] ? images[i].src : '';
        if (imgSrc)
          files.push(
            decodeURI(
              imgSrc
                .split('\\')
                .pop()
                .split('/')
                .pop()
            )
          );
      }
      this.updateSettingOptions({ footer: this.footerContent });
      this.isEditingFooter = false;
    },
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
