//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionList from '@/components/PortalSite/SectionList.vue';
import SearchBox from '@/views/project/page/components/SearchBox';
import { AddPageActionCellRenderer } from './grid';
import {
  NameCellRenderer,
  CreatedCellRenderer,
  PhotoCellRenderer,
  PathCellRenderer,
  TemplateCellRenderer,
  DesCellRenderer
} from '@/views/project/page/grid';
export default {
  components: {
    SectionList,
    SearchBox,
    // Grid
    AddPageActionCellRenderer,
    NameCellRenderer,
    CreatedCellRenderer,
    PhotoCellRenderer,
    PathCellRenderer,
    TemplateCellRenderer,
    DesCellRenderer
  },

  props: {
    page: { type: Object, default: () => ({}) },
    project: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      context: { componentParent: this },
      activeProjectId: null
    };
  },
  computed: {
    baseDomain() {
      return process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
    },
    columnDefs() {
      return [
        {
          headerName: 'project.grid.Actions',
          lockPosition: true,
          pinned: 'left',
          valueGetter: () => {
            return {
              button: {
                add: {
                  loading: this.page.loading
                }
              }
            };
          },
          width: 60,
          cellRenderer: 'AddPageActionCellRenderer'
        },
        {
          headerName: 'project.grid.Photo',
          field: 'photo',
          width: 150,
          lockPosition: true,
          cellRenderer: 'PhotoCellRenderer'
        },
        {
          headerName: 'page.grid.Name',
          field: 'name',
          lockPosition: true,
          cellRenderer: 'NameCellRenderer'
        },
        {
          headerName: 'project.grid.Path',
          field: 'slug',
          width: 120,
          lockPosition: true,
          cellRenderer: 'PathCellRenderer'
        },
        {
          headerName: 'project.grid.Template',
          field: 'type',
          width: 150,
          valueGetter: () => {
            return {
              button: {
                publish: {
                  visible: false
                },
                template: {
                  clickable: false
                }
              }
            };
          },
          lockPosition: true,
          cellRenderer: 'TemplateCellRenderer'
        },
        {
          headerName: 'project.grid.Description',
          field: 'description',
          lockPosition: true,
          cellRenderer: 'DesCellRenderer'
        },
        {
          headerName: 'project.grid.Created',
          field: 'created',
          width: 120,
          lockPosition: true,
          cellRenderer: 'CreatedCellRenderer'
        }
      ];
    }
  },

  methods: {
    selectAny({ checked }, page) {
      this.selectPages({ type: 'any', checked, data: page });
    },
    selectPages({ type, checked, data }) {
      this.$emit('selectPages', { type, checked, data });
    },
    handleAddPage(page) {
      this.$emit('addPage', page);
    },
    handleOnOpenProjectCollapse(key) {
      this.activeProjectId = key;
      if (this.activeProjectId) this.$emit('getPageByProjectId', this.activeProjectId);
    },
    handlePageChange({ page, pageSize }) {
      this.$emit('getPageByProjectId', this.activeProjectId, { page, pageSize });
    },
    handleSelectPage(event) {
      let nodes = event.api.getSelectedNodes();
      this.selectedItems = nodes.map(node => node.data);
    },
    handleSearchPage() {
      this.page.pagination.current = 1;
      this.$emit('getPageByProjectId', this.activeProjectId);
    },
    handleResetPage() {
      this.page.search = {
        title: '',
        slug: ''
      };
      this.page.pagination.current = 1;
      this.$emit('getPageByProjectId', this.activeProjectId);
    }
  }
};
