//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionGroup from '@/components/PortalSite/SectionGroup.vue';
import { convertToValidSlug } from '@/utils/util';

export default {
  components: { SectionGroup },

  props: {
    portalSite: { type: Object, default: () => ({}) },
    iframeCode: { type: String, default: '' },
    slug: { type: [String, Number], default: '' },
    publishUrl: { type: String, default: '' },
    baseUrl: { type: String, default: '' },
    isPrimary: { type: Boolean, default: false }
  },

  data() {
    return {
      newSlug: '',
      expanded: this.$ls.get('uv-exp-publish-info-section') ?? true,
      slugTimer: undefined
    };
  },

  computed: {
    projectId() {
      return this.$route.params.projectId;
    },
    isPublic() {
      return this.portalSite.ispublic;
    }
  },

  watch: {
    slug() {
      this.newSlug = this.slug;
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-publish-info-section', this.expanded);
    },
    changeIframeCode({ target }) {
      this.$emit('change-iframe-code', target.value);
    },
    openPortalSite() {
      window.open(this.publishUrl, '_blank');
    },
    copyPublishURL() {
      this.$copyText(this.publishUrl)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          console.log(err);
          this.$message.error(this.$t('Copy failed'));
        });
    },
    copyIframeCode() {
      this.$copyText(this.iframeCode)
        .then(() => {
          this.$message.success(this.$t('Copy completed'));
        })
        .catch(err => {
          console.log(err);
          this.$message.error(this.$t('Copy failed'));
        });
    },
    changeSlug(value) {
      this.$emit('update-slug', { slug: value });
    },
    handleOnChangeSlug({ target: { value } }) {
      clearTimeout(this.slugTimer);
      this.slugTimer = setTimeout(async () => {
        if (value) {
          this.newSlug = convertToValidSlug(value);
        }
      }, 1000);
    },
    goToEditProject() {
      this.$router.push(`/projects/${this.projectId}`);
    }
  }
};
