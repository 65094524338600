//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Draggable from 'vuedraggable';
import SectionGroup from '@/components/PortalSite/SectionGroup.vue';
import CategoryEdit from './components/category-edit/Index.vue';
import CategoryItem from './components/CategoryItem.vue';

export default {
  components: {
    Draggable,
    SectionGroup,
    CategoryEdit,
    CategoryItem
  },

  props: {
    portalSite: { type: Object, required: true, default: () => ({}) }
  },

  data() {
    return {
      expanded: true,
      categoryComposeVisible: false,
      categoryEditVisible: false,

      categories: [],
      categoryEdit: {},
      pagesByCategory: {},
      createCategoryForm: this.$form.createForm(this, { name: 'create-category-form' })
    };
  },

  computed: {
    formItemLayout() {
      return {
        labelCol: {
          xs: { span: 10 },
          lg: { span: 6 }
        },
        wrapperCol: {
          xs: { span: 14 },
          lg: { span: 18 }
        }
      };
    },
    formItemOptions() {
      return {
        name: { rules: [{ required: true, message: this.$t('Please input category name!') }] }
      };
    },
    pageApi() {
      return this.$s.api.page;
    },
    pageGroupApi() {
      return this.$s.api.pageGroup;
    }
  },

  created() {
    this.initData();
  },

  methods: {
    async initData() {
      try {
        this.categories = await this.pageApi.getCategoriesByPortalPageId({
          id: this.portalSite.id,
          urlParams: { filter: { order: 'order_by' } }
        });
        this.categories.forEach(async category => {
          const data = await this.pageGroupApi.getPageByCategoryId({
            id: category.id,
            urlParams: {
              filter: {
                order: 'order_by',
                include: {
                  relation: 'project',
                  scope: { fields: ['status', 'ispublic', 'campaign_id'] }
                }
              }
            }
          });
          this.pagesByCategory = { ...this.pagesByCategory, [category.id]: data };
        });
      } catch (error) {
        console.log(error);
      }
    },
    addNewCategory() {
      this.createCategoryForm.validateFieldsAndScroll(async (error, values) => {
        if (!error) {
          try {
            const data = await this.pageApi.createCategoryByPortalPageId({
              id: this.portalSite.id,
              payload: values
            });
            this.categoryComposeVisible = false;
            this.createCategoryForm.resetFields();
            this.categories = [...this.categories, data];
            this.pagesByCategory = { ...this.pagesByCategory, [data.id]: [] };
            // Open category edit modal
            this.openCategoryEdit(data);
          } catch (error) {
            console.log(error);
          }
        }
      });
    },
    updateCategory(data) {
      const newCategories = this.categories.map(item => (item.id !== data.id ? item : { ...data }));
      this.categories = [...newCategories];
      this.categoryEdit = { ...data };
    },
    openCategoryEdit(category) {
      this.categoryEdit = { ...category };
      this.categoryEditVisible = true;
    },
    deleteCategory(category) {
      this.categories = this.categories.filter(item => item.id !== category.id);
      this.$emit('foreReloadIframe');
    },
    addPages({ categoryId, data }) {
      if (Array.isArray(data)) {
        this.pagesByCategory = {
          ...this.pagesByCategory,
          [categoryId]: [...this.pagesByCategory[categoryId], ...data]
        };
      } else {
        this.pagesByCategory = {
          ...this.pagesByCategory,
          [categoryId]: [...this.pagesByCategory[categoryId], data]
        };
      }
    },
    updatePage({ categoryId, page }) {
      const newPages = this.pagesByCategory[categoryId];
      this.pagesByCategory[categoryId] = newPages.map(item =>
        item.id === page.id ? { ...page } : item
      );
    },
    deletePage({ categoryId, pageId }) {
      const pagesByCategoryId = this.pagesByCategory[categoryId];

      this.pagesByCategory[categoryId] = pagesByCategoryId.filter(item => item.id !== pageId);
      this.pagesByCategory = {
        ...this.pagesByCategory,
        [categoryId]: [...this.pagesByCategory[categoryId]]
      };
    },
    closeCategoryEdit() {
      this.categoryEditVisible = false;
      this.$emit('foreReloadIframe');
    },
    dragCategoriesEnd({ newIndex }) {
      try {
        let orderBy = 0;
        const firstCategory = this.categories[newIndex + 1];
        const secondCategory = this.categories[newIndex - 1];
        if (newIndex === 0) {
          orderBy = firstCategory?.order_by - 0.5;
        } else {
          orderBy = (firstCategory?.order_by + secondCategory?.order_by) / 2;
        }
        this.categories[newIndex].order_by = orderBy;
        this.$s.api.page.updateCategoryByPortalPageId({
          pageId: this.portalSite.id,
          categoryId: this.categories[newIndex].id,
          payload: { order_by: orderBy }
        });
        this.$emit('foreReloadIframe');
      } catch (error) {
        console.log(error);
      }
    },
    movePageToCategory({ categoryIdFrom, categoryIdTo, page }) {
      this.pagesByCategory = {
        ...this.pagesByCategory,
        [categoryIdTo]: [...this.pagesByCategory[categoryIdTo], page],
        [categoryIdFrom]: this.pagesByCategory[categoryIdFrom].filter(item => item.id !== page.id)
      };
      this.$emit('foreReloadIframe');
    }
  }
};
