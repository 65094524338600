var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "external-tab" } },
    [
      _c(
        "a-form",
        _vm._b(
          {
            attrs: { form: _vm.form, "label-align": "left" },
            on: {
              submit: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleSubmit($event)
              }
            }
          },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-form-item",
            { attrs: { label: "URL" } },
            [
              _vm.newPage.name
                ? _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["url", _vm.formItemOptions.url],
                        expression: "['url', formItemOptions.url]"
                      }
                    ],
                    attrs: { loading: _vm.loading }
                  })
                : _c("a-input-search", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["url", _vm.formItemOptions.url],
                        expression: "['url', formItemOptions.url]"
                      }
                    ],
                    attrs: { "enter-button": "OK", loading: _vm.loading },
                    on: { search: _vm.loadMetaData }
                  })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("Title") } },
            [
              _c("a-input", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["name", _vm.formItemOptions.title],
                    expression: "['name', formItemOptions.title]"
                  }
                ]
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            { attrs: { label: _vm.$t("Descriptions") } },
            [
              _c("a-textarea", {
                directives: [
                  {
                    name: "decorator",
                    rawName: "v-decorator",
                    value: ["description", _vm.formItemOptions.description],
                    expression: "['description', formItemOptions.description]"
                  }
                ],
                attrs: { rows: 3 }
              })
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c("a-col", { attrs: { xs: 12, lg: 4 } }, [
                _c("span", { staticClass: "image-label" }, [
                  _vm._v(_vm._s(_vm.$t("Image")))
                ])
              ]),
              _c(
                "a-col",
                { attrs: { xs: 12, lg: 20 } },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        "list-type": "picture-card",
                        accept: ".png, .jpg, .jpeg, .gif, .png, .webp, .ico",
                        "file-list": _vm.file && _vm.file.url ? [_vm.file] : [],
                        "custom-request": _vm.uploadS3,
                        "before-upload": _vm.beforeUpload,
                        remove: _vm.handleRemoveFile
                      },
                      on: { preview: _vm.handlePreviewPhoto }
                    },
                    [
                      !_vm.file || !_vm.file.url
                        ? _c(
                            "div",
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _c("div", { staticClass: "ant-upload-text" }, [
                                _vm._v(" " + _vm._s(_vm.$t("Upload")) + " ")
                              ])
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            _vm._b(
              { staticClass: "mb-none" },
              "a-form-item",
              _vm.tailFormItemLayout,
              false
            ),
            [
              _c(
                "a-button",
                {
                  staticStyle: { "min-width": "104px", "margin-right": "8px" },
                  attrs: {
                    type: "primary",
                    icon: _vm.isEditMode ? "save" : "plus",
                    "html-type": "submit"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isEditMode ? _vm.$t("Save") : _vm.$t("Add")) +
                      " "
                  )
                ]
              ),
              _vm.isEditMode
                ? _c(
                    "a-button",
                    {
                      staticClass: "mr-xs",
                      attrs: { icon: "close", type: "primary", ghost: "" },
                      on: {
                        click: function() {
                          return _vm.$emit("cancel")
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                  )
                : _vm._e(),
              _c("a-button", { on: { click: _vm.handleResetFormData } }, [
                _vm._v(_vm._s(_vm.$t("Clear form data")))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: {
            cancel: function() {
              return (_vm.previewVisible = false)
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "alt text", src: _vm.file && _vm.file.url }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }