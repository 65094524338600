//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { LANGUAGES } from '@/enum';
import SectionGroup from '@/components/PortalSite/SectionGroup.vue';
import PickerColor from '@/components/tools/PickerColor.vue';
import UploadPhoto from '@/components/spec/UploadPhoto.vue';
import TextEditor from '@/components/tools/TextEditor.vue';
import { DEFAULT_PORTAL_SETTING } from '@/enum';

export default {
  components: { SectionGroup, PickerColor, UploadPhoto, TextEditor },

  props: {
    settingOptions: { type: Object, require: true, default: () => ({}) },
    portalSite: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      sortOptions: [
        { label: 'New arrival order', value: 'DESC' },
        { label: 'Oldest first', value: 'ASC' }
      ],
      pageTitleFontSizeOptions: [
        { label: 'X-Small', value: 'x-small' },
        { label: 'Small', value: 'small' },
        { label: 'Medium', value: 'medium' },
        { label: 'Large', value: 'large' },
        { label: 'X-Large', value: 'x-large' }
      ],
      alignOptions: [
        { label: 'Left', value: 'left' },
        { label: 'Center', value: 'center' },
        { label: 'Right', value: 'right' }
      ],
      typeOfColumnOptions: [
        { label: 'Flexible', value: 'flexible' },
        { label: 'Fixed', value: 'fixed' }
      ],
      numberOfColumnOptions: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 }
      ],
      pageTitle: '',
      itemNaming: '',
      expanded: this.$ls.get('uv-exp-page-option-section') ?? false,
      introductionContent: null,
      showPageIntroductionEditor: false,
      logoCaption: ''
    };
  },

  watch: {
    settingOptions: {
      handler() {
        this.initData(this.settingOptions);
      },
      immediate: true
    }
  },

  computed: {
    ...mapGetters(['currentLanguage']),
    pageLangOptions() {
      return LANGUAGES[this.currentLanguage];
    },
    showPageTitle() {
      return this.settingOptions?.show_page_title ?? true;
    },
    showHeader() {
      return this.settingOptions?.show_header ?? true;
    },
    showFooter() {
      return this.settingOptions?.show_footer ?? true;
    },
    showSharing() {
      return this.settingOptions?.show_sharing ?? true;
    },
    pageLang() {
      return this.settingOptions?.language || 'jpn';
    },
    createdOrder() {
      return this.settingOptions?.created_order || 'DESC';
    },
    pageTitleColor() {
      return this.settingOptions?.page_title_color || '#4a86e8';
    },
    bgColor() {
      return this.settingOptions?.bg_color || '#c9daf8';
    },
    headingColor() {
      return this.settingOptions?.heading_color || '#4a86e8';
    },
    projectSetting() {
      return this.settingOptions?.project_setting || {};
    },
    projectTitleColor() {
      return this.projectSetting?.title_color || '#000000';
    },
    projectDesColor() {
      return this.projectSetting?.description_color || '#9b9b9b';
    },
    folderNameS3() {
      return `${process.env.VUE_APP_S3_PORTAL_THUMBNAIL}/${this.portalSite?.id || ''}`;
    },
    faviconURL() {
      return this.settingOptions?.favicon || '';
    },
    // ToDo: Introduction setting
    introductionSetting() {
      return this.settingOptions?.introduction || { show_introduction: false, content: null };
    },
    showIntroduction() {
      return this.introductionSetting?.show_introduction ?? false;
    },

    // ToDo: Menu setting
    menuSetting() {
      return this.settingOptions?.menu_setting || { type: 'icon' };
    },
    menuType() {
      return this.menuSetting?.type || 'icon';
    },

    // ToDo: Page logo setting
    pageLogoSetting() {
      return this.settingOptions?.page_logo || { logo_url: '', show_logo: false, logo_caption: '' };
    },
    showLogo() {
      return this.pageLogoSetting?.show_logo ?? false;
    },
    logoURL() {
      return this.pageLogoSetting?.logo_url || '';
    },

    pageTitleAlign() {
      return this.settingOptions?.page_title_align || 'center';
    },
    pageTitleFontSize() {
      return this.settingOptions?.page_title_font_size || 'medium';
    },

    // Column setting
    columnSetting() {
      return this.settingOptions?.column_setting || DEFAULT_PORTAL_SETTING?.column_setting;
    }
  },

  methods: {
    initData(data) {
      this.pageTitle = data?.header_title || this.portalSite.name || '';
      this.itemNaming = data?.item_naming || '';
      this.introductionContent = this.settingOptions?.introduction?.content;
      this.logoCaption =
        data?.page_logo?.logo_caption || data?.header_title || this.portalSite.name || '';
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-page-option-section', this.expanded);
    },
    togglePageTitle({ target }) {
      this.updateSettingOptions({ show_page_title: target.checked });
    },
    toggleHeader({ target }) {
      this.updateSettingOptions({ show_header: target.checked });
    },
    toggleFooter({ target }) {
      this.updateSettingOptions({ show_footer: target.checked });
    },
    toggleSharing({ target }) {
      this.updateSettingOptions({ show_sharing: target.checked });
    },
    changePageLang(value) {
      this.updateSettingOptions({ language: value });
    },
    changeCreatedOrder(value) {
      this.updateSettingOptions({ created_order: value });
    },
    changeItemNaming(value) {
      this.updateSettingOptions({ item_naming: value });
    },

    // ToDo: Change project setting
    changeProjectTitleColor(value) {
      this.changeProjectSetting({ title_color: value });
    },
    changeProjectDesColor(value) {
      this.changeProjectSetting({ description_color: value });
    },
    changeProjectSetting(value) {
      this.updateSettingOptions({
        project_setting: { ...this.projectSetting, ...value }
      });
    },

    changePageTitle(value) {
      this.updateSettingOptions({ header_title: value, is_edited_header_title: true });
    },
    changePageTitleColor(value) {
      this.updateSettingOptions({ page_title_color: value });
    },
    changePageTitleAlign(value) {
      this.updateSettingOptions({ page_title_align: value });
    },
    changePageTitleFontSize(value) {
      this.updateSettingOptions({ page_title_font_size: value });
    },
    changeBgColor(value) {
      this.updateSettingOptions({ bg_color: value });
    },
    changeHeadingColor(value) {
      this.updateSettingOptions({ heading_color: value });
    },
    changeFavicon(value) {
      this.updateSettingOptions({ favicon: value });
    },
    deleteFavicon() {
      this.updateSettingOptions({ favicon: '' });
    },

    // ToDo: Change menu setting
    changeMenuSetting(value) {
      this.updateSettingOptions({ menu_setting: { ...this.menuSetting, ...value } });
    },
    changeMenuType(value) {
      this.changeMenuSetting({ type: value });
    },

    // ToDo: Change page logo setting
    changePageLogoSetting(value) {
      const headerTitle = this.settingOptions?.header_title || '';
      const portalSiteName = this.portalSite?.name || '';
      const page_logo = {
        ...this.pageLogoSetting,
        ...value
      };
      if (!page_logo.logo_caption) {
        page_logo.logo_caption = headerTitle || portalSiteName;
      }
      this.updateSettingOptions({ page_logo });
    },
    toggleLogo({ target }) {
      this.changePageLogoSetting({ show_logo: target.checked });
    },
    changeLogoURL(value) {
      this.changePageLogoSetting({ logo_url: value });
    },
    deleteLogoURL() {
      this.changePageLogoSetting({ logo_url: '' });
    },
    changeLogoCaption(value) {
      this.changePageLogoSetting({ logo_caption: value });
    },

    // ToDo: Change introduction setting
    changeIntroductionContent(value) {
      this.introductionContent = value || null;
    },
    updateIntroductionContent() {
      this.showPageIntroductionEditor = false;
      this.changeIntroductionSetting({ content: this.introductionContent });
    },
    toggleIntroduction({ target }) {
      if (target.checked) {
        this.showPageIntroductionEditor = true;
      }
      this.changeIntroductionSetting({ show_introduction: target.checked });
    },
    changeIntroductionSetting(value) {
      this.updateSettingOptions({ introduction: { ...this.introductionSetting, ...value } });
    },

    // ToDo: Change column setting
    updateColumnSetting(value) {
      this.updateSettingOptions({ column_setting: { ...this.columnSetting, ...value } });
    },
    changeColumnType(value) {
      this.updateColumnSetting({ type: value });
    },
    changeColumnNumber(value) {
      this.updateColumnSetting({ number: value });
    },

    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
