//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { uploadFileToS3 } from '@/api/s3';
import { validateURL } from '@/utils/util';

export default {
  props: {
    type: { type: String, default: 'add' },
    category: { type: Object, default: () => ({}) },
    pageEdit: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      form: this.$form.createForm(this, { name: 'manual-form' }),
      file: {},
      previewVisible: false,
      newPage: {},
      loading: false
    };
  },

  watch: {
    pageEdit: {
      handler() {
        this.file = {
          uid: '-1',
          name: 'name',
          status: 'done',
          url: this.pageEdit?.thumbnail?.url || ''
        };
        this.newPage = { ...this.pageEdit };
      },
      immediate: true
    }
  },

  computed: {
    isEditMode() {
      return this.type === 'edit';
    },
    formItemLayout() {
      return {
        labelCol: { sm: { span: 12 }, lg: { span: 4 } },
        wrapperCol: { sm: { span: 12 }, lg: { span: 20 } }
      };
    },
    tailFormItemLayout() {
      return {
        wrapperCol: { sm: { span: 12, offset: 12 }, lg: { span: 20, offset: 4 } }
      };
    },
    formItemOptions() {
      return {
        title: {
          initialValue: this.newPage.name || '',
          rules: [{ required: true, message: this.$t('Please input your title!') }]
        },
        description: {
          initialValue: this.newPage.description || '',
          rules: [{ message: this.$t('Please input your description!') }]
        },
        url: {
          initialValue: this.newPage.url || '',
          rules: [
            { required: true, message: this.$t('Please input your url!') },
            { validator: this.urlValidator }
          ]
        }
      };
    }
  },

  methods: {
    urlValidator(rule, value, callback) {
      if (value && !validateURL(value)) {
        callback(this.$t('This URL is invalid!'));
      }
      callback();
    },
    handlePreviewPhoto() {
      this.previewVisible = true;
    },
    handleRemoveFile() {
      this.file = {};
    },
    async loadMetaData(value) {
      this.loading = true;
      fetch(`https://kotsukotsu-ogp-api.vercel.app/api/ogp?url=${value}`)
        .then(res => res.text())
        .then(data => {
          const { title, description, image } = JSON.parse(data);
          this.form.setFieldsValue({
            name: title,
            description: description
          });
          this.file.url = image;
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    async uploadS3({ file, onSuccess, onError }) {
      try {
        this.file.status = 'uploading';
        const key = `${process.env.VUE_APP_S3_PORTAL_SITE}/${file.type}/${file.name}`;
        const url = await uploadFileToS3({ key, contentType: file.type, file });
        this.file = { uid: '-1', name: file.name, status: 'done', url: url };
        onSuccess(null, file);
      } catch (error) {
        console.log(error);
        onError(error);
        this.file.status = 'error';
      }
    },
    async beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$notification.warning({
          message: 'アップロードできるのは、1ファイルあたり最大10MBまで、。'
        });
      }
      return isLt2M;
    },
    handleResetFormData() {
      this.form.resetFields();
      this.newPage = {};
      this.file = {};
    },
    handleSubmit() {
      this.form.validateFieldsAndScroll((error, values) => {
        if (!error) {
          const payload = {
            ...values,
            ...{ thumbnail: this.file?.url ? { url: this.file.url } : {} }
          };
          this.isEditMode ? this.updatePage(payload) : this.addPage(payload);
        }
      });
    },
    async addPage(payload) {
      try {
        this.loading = true;
        const data = await this.$s.api.pageGroup.createPageByCategoryId({
          id: this.category.id,
          payload
        });
        this.$emit('addPages', { categoryId: this.category.id, data });
        this.handleResetFormData();
        this.$notification.success({
          message: this.$t('The sites have been added!')
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('Adding sites failed!')
        });
      } finally {
        this.loading = false;
      }
    },
    async updatePage(payload) {
      try {
        this.loading = true;
        const data = await this.$s.api.pageGroup.updatePageByCategoryId({
          categoryId: this.category.id,
          pageId: this.pageEdit.id,
          payload
        });
        this.$emit('updatePage', { categoryId: this.category.id, page: data });
        this.handleResetFormData();
        this.$notification.success({
          message: this.$t('This site has been updated!')
        });
      } catch (error) {
        this.$notification.error({
          message: this.$t('Updating site failed!')
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
