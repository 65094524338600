var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "a-spin",
        { attrs: { spinning: !_vm.project.data.length } },
        [
          _c(
            "div",
            { staticStyle: { width: "100%" } },
            [
              _c("search-box", {
                staticClass: "pb-sm",
                attrs: { queryParam: _vm.project.search },
                on: {
                  changeTitle: function(value) {
                    return (_vm.project.search.title = value)
                  },
                  changeSubDomain: function(value) {
                    return (_vm.project.search.sub_domain = value)
                  },
                  handleSearch: _vm.handleSearchProject,
                  handleReset: _vm.handleResetProject
                }
              })
            ],
            1
          ),
          _vm.project.data.length
            ? _c("suggestion", {
                attrs: { page: _vm.page, project: _vm.project },
                on: {
                  getPageByProjectId: _vm.handleGetPageByProjectId,
                  addPage: _vm.handleAddPage
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "a-button",
        {
          attrs: {
            icon: "caret-down",
            type: "primary",
            loading: _vm.project.loading,
            disabled: _vm.project.pagination.total <= _vm.project.data.length
          },
          on: { click: _vm.handleLoadMoreProjects }
        },
        [_vm._v(" " + _vm._s(_vm.$t("Show more")) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }