var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section-group",
        {
          attrs: { topic: _vm.$t("Website configure"), expanded: _vm.expanded },
          on: {
            toggleExpanded: function() {
              return (_vm.expanded = !_vm.expanded)
            }
          }
        },
        [
          _c(
            "a-row",
            { staticClass: "mb-xs content-between", attrs: { type: "flex" } },
            [
              _c("div", { staticClass: "important-note" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "*This is the category configure of the public version"
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "a-button",
                {
                  attrs: { icon: "plus", type: "primary" },
                  on: {
                    click: function() {
                      return (_vm.categoryComposeVisible = true)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Add new category")) + " ")]
              )
            ],
            1
          ),
          _c(
            "draggable",
            {
              on: { end: _vm.dragCategoriesEnd },
              model: {
                value: _vm.categories,
                callback: function($$v) {
                  _vm.categories = $$v
                },
                expression: "categories"
              }
            },
            _vm._l(_vm.categories, function(category) {
              return _c("category-item", {
                key: category.id,
                attrs: {
                  "portal-site": _vm.portalSite,
                  category: category,
                  categories: _vm.categories,
                  pages: _vm.pagesByCategory
                },
                on: {
                  loadPages: _vm.initData,
                  openCategoryEdit: function() {
                    return _vm.openCategoryEdit(category)
                  },
                  deleteCategory: _vm.deleteCategory,
                  movePageToCategory: _vm.movePageToCategory
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.categoryEditVisible
        ? _c("category-edit", {
            attrs: {
              visible: _vm.categoryEditVisible,
              "portal-site": _vm.portalSite,
              category: _vm.categoryEdit,
              pages: _vm.pagesByCategory[_vm.categoryEdit.id]
            },
            on: {
              updateCategory: _vm.updateCategory,
              addPages: _vm.addPages,
              updatePage: _vm.updatePage,
              loadPages: _vm.initData,
              deletePage: _vm.deletePage,
              close: _vm.closeCategoryEdit
            }
          })
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            visible: _vm.categoryComposeVisible,
            "ok-text": _vm.$t("Create"),
            "cancel-text": _vm.$t("Close")
          },
          on: {
            cancel: function() {
              return (_vm.categoryComposeVisible = false)
            },
            ok: _vm.addNewCategory
          }
        },
        [
          _c("br"),
          _c(
            "a-form",
            _vm._b(
              { attrs: { form: _vm.createCategoryForm } },
              "a-form",
              _vm.formItemLayout,
              false
            ),
            [
              _c(
                "a-form-item",
                {
                  staticClass: "mb-none",
                  attrs: { label: _vm.$t("Category name") }
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["name", _vm.formItemOptions.name],
                        expression: "['name', formItemOptions.name]"
                      }
                    ]
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }