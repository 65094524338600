var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-row",
        { attrs: { type: "flex", align: "middle", justify: "space-around" } },
        [
          _c(
            "a-popconfirm",
            {
              attrs: {
                "ok-text": _vm.$t("Add"),
                "cancel-text": _vm.$t("Cancel"),
                disabled: _vm.loading
              },
              on: { confirm: _vm.handleAddPage }
            },
            [
              _c("template", { slot: "title" }, [
                _c("div", {
                  staticClass: "mb-xs font-bold",
                  domProps: { innerHTML: _vm._s(_vm.confirmTitleHtml) }
                })
              ]),
              _c("a-button", {
                attrs: {
                  icon: "plus",
                  type: "primary",
                  ghost: "",
                  disabled: _vm.loading
                }
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }