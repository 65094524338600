//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Alert from './Alert.vue';
import PublishInfo from './PublishInfo.vue';
import PageOptions from './PageOptions.vue';
import ItemDisplayOptions from './ItemDisplayOptions.vue';
import SearchOptions from './SearchOptions.vue';
import WebsiteConfigure from './website-configure/Index.vue';
import Preview from './Preview.vue';
import { PAGE_STATE } from '@/enum';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import { PublishWebsiteModal, RevisionHistoryModal } from '../iframe-code-list/modals';
import localStorageKey from '@/constant/local-storage-key.js';

export default Vue.extend({
  components: {
    Alert,
    PublishInfo,
    PageOptions,
    ItemDisplayOptions,
    SearchOptions,
    WebsiteConfigure,
    Preview,
    // modals
    PublishWebsiteModal,
    RevisionHistoryModal
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    page: { type: Object, require: true, default: () => ({}) }
  },
  data() {
    return {
      newPage: {},
      siteOpts: {},
      loading: false,
      publish: {
        visible: false,
        loading: false
      },
      revision: {
        visible: false,
        loading: false,
        items: []
      },
      havePublishVersion: false,
      draftIframeCode: `<iframe loading="lazy" src="" style="border: 1px solid #c4c4c4; min-height: 210px;" width=100% height=100%></iframe>`,
      iframeCode: `<iframe loading="lazy" src="" style="border: 1px solid #c4c4c4; min-height: 210px;" width=100% height=100%></iframe>`,
      viewMode: [
        {
          value: 'pc',
          label: this.$t('PC Preview'),
          width: '100%',
          height: '100%'
        },
        {
          value: 'mobile',
          label: this.$t('Mobile Preview'),
          width: '450px',
          height: '896px'
        }
      ]
    };
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    companyId() {
      return this.userInfo?.company_id;
    },
    publishUrl() {
      return `${this.baseUrl}/${this.pageSlug}`;
    },
    accessToken() {
      return this.$ls.get(ACCESS_TOKEN);
    },
    baseUrl() {
      return `https://${this.currentProject?.sub_domain}${this.baseSubDomain}`;
    },
    baseSubDomain() {
      return process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
    },
    pageSlug() {
      if (this.isPrimaryState) return '';
      return this.newPage.slug || this.newPage.id;
    },
    settingOptions() {
      return this.siteOpts || {};
    },
    pageId() {
      return this.page.id;
    },
    projectId() {
      return this.page.campaign_id;
    },
    isPrimaryState() {
      return this.page.state === PAGE_STATE.PRIMARY;
    },
    currentProject() {
      return this.projectById(this.projectId);
    },
    isDraftEdited() {
      return this.siteOpts?.is_draft_edited || false;
    },
    disabledPublishButton() {
      if (!this.havePublishVersion) return false;
      return !this.isDraftEdited || this.loading;
    }
  },

  watch: {
    publishUrl: {
      handler() {
        this.iframeCode = this.iframeCode.replace(/src="(.*?)"/g, `src="${this.publishUrl}"`);
      },
      immediate: true
    }
  },

  async mounted() {
    const that = this;
    window.addEventListener(
      'message',
      function (e) {
        if (e?.data?.height) {
          that.iframeCode = that.iframeCode.replace('height=100%', `height=${e.data.height}px`);
          that.viewMode[0].height = `${e.data.height}px`;
          that.draftIframeCode = that.draftIframeCode.replace(
            'height=100%',
            `height=${e.data.height}px`
          );
        }
      },
      false
    );
    await this.initData();
  },

  beforeDestroy() {
    window.removeEventListener('message', null);
  },

  methods: {
    ...mapActions('project', ['getProjectById']),
    async initData() {
      try {
        const [newPage, siteOpts, totalPublished] = await Promise.all([
          this.$s.api.page.getPageById(this.projectId, this.page.id),
          this.$s.api.page.getDraftSiteOpts(this.page.id),
          this.$s.api.page.countSiteOpts(this.page.id, { where: { status: 'PUBLISHED' } })
        ]);
        this.newPage = newPage;
        this.siteOpts = siteOpts;
        const draftUrl = await this.getDraftUrl(this.siteOpts.id)
        this.draftIframeCode = this.draftIframeCode.replace(
          /src="(.*?)"/g,
          `src="${draftUrl}"`
        );
        this.havePublishVersion = totalPublished.count > 0;
      } catch (error) {
        console.log('Error: ', error)
      }
    },
    handleCancel() {
      this.$emit('cancel');
    },
    changeIframeCode(value) {
      this.iframeCode = value;
    },
    changeDraftIframeCode(value) {
      this.draftIframeCode = value;
    },
    updateSettingOptions(value) {
      // const notifyMessage = {
      //   success: this.$t('Save successfully.'),
      //   error: this.$t('Save failed.')
      // };
      this.updatePageSettingOptions(value);
    },
    updateSlug(value) {
      const notifyMessage = {
        success: this.$t('This portal sub directory name is updated.'),
        error: null
      };
      this.updatePortal({ ...value }, notifyMessage);
    },
    async updatePortal(payload, notifyMessage) {
      try {
        const data = await this.$s.api.page.editPageById(this.projectId, this.pageId, payload);
        this.newPage = { ...data };
        this.$notification.success({ message: notifyMessage.success });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: notifyMessage.error || this.$t(error.message) });
      } finally {
        this.resetDraftIframeCode();
      }
    },
    async handleDiscardDraft() {
      try {
        this.loading = true;
        const originDraftSiteOpts = await this.$s.api.page.discardDraftSiteOpts(this.pageId);
        this.siteOpts = originDraftSiteOpts;
        this.$notification.success({ message: this.$t('Discard changes successfully.') });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t(error.message) });
      } finally {
        this.loading = false;
        this.publish.visible = false;
        this.resetDraftIframeCode();
      }
    },
    async updatePageSettingOptions(input) {
      try {
        this.loading = true;
        this.siteOpts = await this.$s.api.page.updateDraftSiteOpts(this.pageId, input);
        // this.$notification.success({ message: notifyMessage.success });
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t(error.message) });
      } finally {
        this.resetDraftIframeCode();
        this.loading = false;
      }
    },
    async handleRevertSettingOptions(data) {
      this.revision.loading = true;
      await this.publishPageSettingOptions(data, data.id);
      this.revision.visible = false;
      this.revision.loading = false;
    },
    async publishPageSettingOptions(input, siteOptsId = null) {
      try {
        this.loading = true;
        this.siteOpts = await this.$s.api.page.publishSiteOpts(
          this.pageId,
          siteOptsId || this.siteOpts.id,
          input
        );
        await this.initData();
        this.revision.items = [];
        if (this.page.ispublic) {
          this.$notification.success({ message: this.$t('New version is released!') });
        } else this.$notification.success({ message: this.$t('New version saved!') });
        this.$emit('publish-new-version', this.page, this.siteOpts);
      } catch (error) {
        console.log(error);
        this.$notification.error({ message: this.$t(error.message) });
      } finally {
        this.loading = false;
        this.publish.visible = false;
        this.resetIframeCode();
      }
    },
    async previewPageSettingOptions(siteOptsData) {
      const previewUrl = await this.getDraftUrl(siteOptsData.id);
      // go to new tab
      window.open(previewUrl, '_blank');
    },
    async getDraftUrl(siteOptsId) {
      try {
        let uvTempToken = JSON.parse(this.$ls.get(localStorageKey.TEMP_TOKEN, "{}"));
        if (
          Object.keys(uvTempToken).length === 0 ||
          moment().isAfter(moment(uvTempToken.expired_date))
        ) {
          uvTempToken = await this.$s.api.user.generateTempToken(this.companyId)
          const expiredDate = moment(uvTempToken.created).local().add(uvTempToken.ttl, 'seconds').toString()
          this.$ls.set(localStorageKey.TEMP_TOKEN, JSON.stringify({
            expired_date: expiredDate,
            id: uvTempToken.id
          }))
        }
        return `${this.baseUrl}/${this.pageSlug}?options=${encodeURIComponent(
          `{"project_site_opts":{"id":${siteOptsId}}}`
        )}&access_token=${uvTempToken.id}`;
      } catch (error) {
        console.log(error)
        return ''
      }
    },
    async handleOpenRevisionHistory() {
      this.revision.visible = true;
      this.revision.loading = true;
      this.revision.items = this.revision.items.length
        ? this.revision.items
        : await this.$s.api.page.getSiteOpts(this.pageId, {
          filter: {
            where: {
              status: 'PUBLISHED'
            },
            order: 'version DESC'
          }
        });
      this.revision.loading = false;
    },
    async resetDraftIframeCode() {
      const draftIframeCodeCopy = this.draftIframeCode;
      this.draftIframeCode = '';
      await new Promise(resolve => setTimeout(resolve, 500));
      this.draftIframeCode = draftIframeCodeCopy;
    },
    async resetIframeCode() {
      const iframeCodeCopy = this.iframeCode;
      this.iframeCode = '';
      await new Promise(resolve => setTimeout(resolve, 500));
      this.iframeCode = iframeCodeCopy;
    }
  }
});
