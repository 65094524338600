var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-group",
    {
      attrs: { topic: _vm.$t("Preview"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        {
          staticClass: "content-between",
          attrs: { gutter: [8, 8], type: "flex" }
        },
        [
          _c(
            "a-col",
            { attrs: { xs: 12 } },
            [
              _c(
                "a-row",
                { attrs: { type: "flex" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      attrs: { size: "large" },
                      model: {
                        value: _vm.previewId,
                        callback: function($$v) {
                          _vm.previewId = $$v
                        },
                        expression: "previewId"
                      }
                    },
                    [
                      _c(
                        "a-radio-button",
                        {
                          staticClass: "draft-radio",
                          attrs: { value: "DRAFT" }
                        },
                        [_vm._v(_vm._s(_vm.$t("Draft version")))]
                      ),
                      _c(
                        "a-radio-button",
                        {
                          staticClass: "published-radio",
                          attrs: { value: "PUBLISHED", disabled: !_vm.isPublic }
                        },
                        [_vm._v(_vm._s(_vm.$t("Public version")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 12 } },
            [
              _c(
                "a-row",
                { attrs: { gutter: [0, 8], type: "flex", justify: "end" } },
                [
                  _c(
                    "a-col",
                    { staticClass: "mr-xs", attrs: { xs: 12, lg: 8 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            "label-in-value": "",
                            "default-value": { key: "pc" }
                          },
                          on: { change: _vm.changeModeIframe }
                        },
                        _vm._l(_vm.viewMode, function(mode) {
                          return _c(
                            "a-select-option",
                            { key: mode.value, attrs: { value: mode.value } },
                            [_vm._v(" " + _vm._s(mode.label) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.isEditingHeader && _vm.showHeader
                    ? _c(
                        "a-col",
                        { attrs: { xs: 24, lg: 12 } },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                icon: "edit",
                                type: "primary",
                                disabled: !_vm.isDraftPreview
                              },
                              on: { click: _vm.openHeaderEditor }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Edit the header")) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isEditingHeader && _vm.showHeader
        ? _c(
            "div",
            { staticClass: "header-editing" },
            [
              _c("text-editor", {
                key: "editor-1",
                attrs: {
                  type: "header",
                  placeholder: _vm.$t("Please input your header"),
                  "project-id": "" + _vm.portalSiteId,
                  "project-lang": _vm.pageLang,
                  content: _vm.headerContent
                },
                on: {
                  change: function($event) {
                    return _vm.changeHeaderContent($event)
                  }
                }
              }),
              _c(
                "a-row",
                {
                  staticClass: "mt-xs",
                  attrs: { gutter: [16, 16], type: "flex", justify: "end" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, lg: 4 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            icon: "close-square",
                            type: "danger",
                            ghost: ""
                          },
                          on: { click: _vm.closeHeaderEditor }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, lg: 4 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { icon: "save", type: "primary" },
                          on: { click: _vm.updateHeaderContent }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("br"),
      !_vm.isDraftPreview
        ? _c("div", {
            staticClass: "iframe-container",
            domProps: { innerHTML: _vm._s(_vm.iframeCode) }
          })
        : _c("div", {
            staticClass: "iframe-container",
            domProps: { innerHTML: _vm._s(_vm.draftIframeCode) }
          }),
      _c("br"),
      !_vm.isEditingFooter && _vm.showFooter
        ? _c(
            "a-row",
            { attrs: { type: "flex", justify: "end" } },
            [
              _c(
                "a-col",
                { attrs: { xs: 24, lg: 6 } },
                [
                  _c(
                    "a-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        icon: "edit",
                        type: "primary",
                        disabled: !_vm.isDraftPreview
                      },
                      on: { click: _vm.openFooterEditor }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Edit the footer")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.isEditingFooter && _vm.showFooter
        ? _c(
            "div",
            { staticClass: "footer-editing" },
            [
              _c("text-editor", {
                key: "editor-1",
                attrs: {
                  content: _vm.footerContent,
                  placeholder: _vm.$t("Please input your footer"),
                  "project-id": "" + _vm.portalSiteId,
                  "project-lang": _vm.pageLang,
                  type: "footer"
                },
                on: {
                  change: function($event) {
                    return _vm.changeFooterContent($event)
                  }
                }
              }),
              _c(
                "a-row",
                {
                  staticClass: "mt-xs",
                  attrs: { gutter: [16, 16], type: "flex", justify: "end" }
                },
                [
                  _c(
                    "a-col",
                    { attrs: { xs: 24, lg: 4 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            icon: "close-square",
                            type: "danger",
                            ghost: ""
                          },
                          on: { click: _vm.closeFooterEditor }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { xs: 24, lg: 4 } },
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { icon: "save", type: "primary" },
                          on: { click: _vm.updateFooterContent }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }