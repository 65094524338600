//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Draggable from 'vuedraggable';
import SectionList from '@/components/PortalSite/SectionList.vue';
import { PROJECT_STATUS } from '@/enum';

export default {
  components: { Draggable, SectionList },

  props: {
    portalSite: { type: Object, default: () => ({}) },
    category: { type: Object, default: () => ({}) },
    pages: { type: Object, default: () => ({}) },
    categories: { type: Array, default: () => [] }
  },

  data() {
    return {
      expanded: false,
      loading: false,
      newPages: []
    };
  },

  watch: {
    pages: {
      handler() {
        this.newPages = this.pages[this.category.id] ? [...this.pages[this.category.id]] : [];
      },
      immediate: true
    }
  },

  computed: {
    categoriesOptions() {
      return this.categories.filter(item => item.id !== this.category.id) || [];
    }
  },

  methods: {
    async deleteCategory() {
      try {
        this.loading = true;
        await this.$s.api.page.deleteCategoryByPortalPageId({
          pageId: this.portalSite.id,
          categoryId: this.category.id
        });
        this.$emit('deleteCategory', this.category);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    dragPagesEnd({ oldIndex, newIndex }) {
      let orderBy = 0;
      if (oldIndex === newIndex) {
        return;
      }
      const firstPage = this.newPages[newIndex + 1];
      const secondPage = this.newPages[newIndex - 1];
      if (newIndex === 0) {
        orderBy = firstPage?.order_by - 0.5;
      } else if (newIndex === this.newPages.length - 1) {
        orderBy = secondPage?.order_by + 0.5;
      } else {
        orderBy = (firstPage?.order_by + secondPage?.order_by) / 2;
      }
      this.newPages[newIndex].order_by = orderBy;
      this.updatePageByCategoryId({
        categoryId: this.category?.id,
        pageId: this.newPages[newIndex]?.id,
        payload: { order_by: orderBy }
      });
      // emit loadPages
      this.$emit('loadPages');
    },
    movePageToCategory({ key }) {
      try {
        const [pageId, categoryId] = key.split('|');
        const tempPages = this.pages[categoryId];
        const tempPagesLength = tempPages?.length || 0;
        this.$s.api.pageGroupMapping.movePageToCategory({
          pageId,
          payload: {
            group_id: categoryId,
            ...(tempPagesLength > 0 && {
              order_by: tempPages[tempPagesLength - 1].order_by + 0.5
            })
          }
        });
        const page = this.newPages.find(item => item.id == pageId);
        this.$emit('movePageToCategory', {
          categoryIdFrom: this.category.id,
          categoryIdTo: categoryId,
          page
        });
      } catch (error) {
        console.log(error);
      }
    },
    updatePageByCategoryId({ categoryId, pageId, payload }) {
      try {
        this.$s.api.pageGroup.updatePageByCategoryId({
          categoryId,
          pageId,
          payload
        });
      } catch (error) {
        console.log(error);
      }
    },
    async handleChangeStatus(page) {
      try {
        const isPublic = !page.is_public;
        await this.$s.api.pageGroup.updatePageByCategoryId({
          categoryId: this.category.id,
          pageId: page.id,
          payload: { is_public: isPublic }
        });
        // emit loadPages
        this.$message.success(`${this.$t('Successful')}`);
        this.$emit('loadPages');
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Failed')}`);
      }
    },
    isArchive(page) {
      return page.project?.status === PROJECT_STATUS.ARCHIVE;
    },
    pageStatus(page) {
      let status = page.is_public;
      if (page.project && page.project.ispublic === false) {
        status = false;
      }
      return status;
    },
    sourcePageStatus(page) {
      return page.project?.ispublic;
    },
    linkTo(page) {
      return {
        name: 'page-detail',
        params: {
          projectId: page.project?.campaign_id,
          pageId: page.project?.id
        }
      };
    }
  }
};
