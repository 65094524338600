//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionGroup from '@/components/PortalSite/SectionGroup.vue';

export default {
  components: { SectionGroup },

  props: {
    settingOptions: { type: Object, require: true, default: () => ({}) }
  },

  data() {
    return {
      expanded: this.$ls.get('uv-exp-item-display-section') ?? false
    };
  },

  computed: {
    projectSetting() {
      return this.settingOptions?.project_setting || {};
    },
    showProjectThumbnail() {
      return this.projectSetting?.show_thumbnail ?? true;
    },
    showProjectDescription() {
      return this.projectSetting?.show_description ?? true;
    }
  },

  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-item-display-section', this.expanded);
    },

    // ToDo: Change project setting
    toggleProjectThumbnail({ target }) {
      this.changeProjectSetting({ show_thumbnail: target.checked });
    },
    toggleProjectDescription({ target }) {
      this.changeProjectSetting({ show_description: target.checked });
    },
    changeProjectSetting(value) {
      this.updateSettingOptions({
        project_setting: { ...this.projectSetting, ...value }
      });
    },

    // ToDo: Update setting options
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
