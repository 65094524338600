//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Draggable from 'vuedraggable';
import SectionList from '@/components/PortalSite/SectionList.vue';
import { PROJECT_STATUS } from '@/enum';

export default {
  components: { Draggable, SectionList },

  props: {
    pages: { type: Array, default: () => ({}) },
    category: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      loading: false,
      expanded: true,
      newPages: []
    };
  },

  watch: {
    pages: {
      handler() {
        this.newPages = [...this.pages];
      },
      immediate: true
    }
  },

  methods: {
    async deletePage(page) {
      try {
        this.loading = true;
        const payload = { pageId: page.id, categoryId: this.category.id };
        await this.$s.api.pageGroup.deletePageByCategoryId(payload);
        this.$emit('deletePage', payload);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    dragPagesEnd({ oldIndex, newIndex }) {
      // This case: this.newPages is re-rendered before we update the order
      try {
        let orderBy = 0;
        if (oldIndex === newIndex) {
          return;
        }
        const firstPage = this.newPages[newIndex + 1];
        const secondPage = this.newPages[newIndex - 1];
        if (newIndex === 0) {
          orderBy = firstPage?.order_by - 0.5;
        } else if (newIndex === this.newPages.length - 1) {
          orderBy = secondPage?.order_by + 0.5;
        } else {
          orderBy = (firstPage?.order_by + secondPage?.order_by) / 2;
        }
        this.newPages[newIndex].order_by = orderBy;
        this.$s.api.pageGroup.updatePageByCategoryId({
          categoryId: this.category.id,
          pageId: this.newPages[newIndex].id,
          payload: { order_by: orderBy }
        });
        // emit loadPages
        this.$emit('loadPages');
      } catch (error) {
        console.log(error);
      }
    },
    async handleChangeStatus(page) {
      try {
        const isPublic = !page.is_public;
        await this.$s.api.pageGroup.updatePageByCategoryId({
          categoryId: this.category.id,
          pageId: page.id,
          payload: { is_public: isPublic }
        });
        // emit loadPages
        this.$message.success(`${this.$t('Successful')}`);
        this.$emit('loadPages');
      } catch (error) {
        console.log(error);
        this.$message.error(`${this.$t('Failed')}`);
      }
    },
    isArchive(page) {
      return page.project?.status === PROJECT_STATUS.ARCHIVE;
    },
    pageStatus(page) {
      let status = page.is_public;
      if (page.project && page.project.ispublic === false) {
        status = false;
      }
      return status;
    },
    sourcePageStatus(page) {
      return page.project?.ispublic;
    },
    linkTo(page) {
      return {
        name: 'page-detail',
        params: {
          projectId: page.project?.campaign_id,
          pageId: page.project?.id
        }
      };
    }
  }
};
