var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-list" }, [
    _c(
      "div",
      { class: ["section-list__header", !_vm.expanded && "collapse"] },
      [
        _c(
          "div",
          {
            class: [
              "section-list__header--left",
              _vm.allowCollapseExpand && "pointer"
            ],
            on: {
              click: function() {
                return _vm.allowCollapseExpand && _vm.$emit("toggleExpanded")
              }
            }
          },
          [
            _vm.allowCollapseExpand
              ? _c("a-icon", {
                  staticClass: "icon",
                  attrs: { type: _vm.expanded ? "up" : "down" }
                })
              : _vm._e(),
            _vm._t("header-left")
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "section-list__header--right" },
          [_vm._t("header-right")],
          2
        )
      ]
    ),
    _c(
      "div",
      { class: ["section-list__body", _vm.expanded && "expanded"] },
      [_vm._t("body")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }