var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-group",
    {
      attrs: { topic: _vm.$t("Item display option"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 0] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Website setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show thumbnail")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showProjectThumbnail },
                        on: { change: _vm.toggleProjectThumbnail }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show description")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showProjectDescription },
                        on: { change: _vm.toggleProjectDescription }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }