import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=5e92af8c&scoped=true&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"
import style0 from "./Index.vue?vue&type=style&index=0&id=5e92af8c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e92af8c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1580246827/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e92af8c')) {
      api.createRecord('5e92af8c', component.options)
    } else {
      api.reload('5e92af8c', component.options)
    }
    module.hot.accept("./Index.vue?vue&type=template&id=5e92af8c&scoped=true&", function () {
      api.rerender('5e92af8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/page/portal-site-editor/website-configure/Index.vue"
export default component.exports