import { render, staticRenderFns } from "./AddPageActionCellRenderer.vue?vue&type=template&id=d50456d8&scoped=true&"
import script from "./AddPageActionCellRenderer.vue?vue&type=script&lang=js&"
export * from "./AddPageActionCellRenderer.vue?vue&type=script&lang=js&"
import style0 from "./AddPageActionCellRenderer.vue?vue&type=style&index=0&id=d50456d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d50456d8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1580246827/src/univoice-company-console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d50456d8')) {
      api.createRecord('d50456d8', component.options)
    } else {
      api.reload('d50456d8', component.options)
    }
    module.hot.accept("./AddPageActionCellRenderer.vue?vue&type=template&id=d50456d8&scoped=true&", function () {
      api.rerender('d50456d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/project/page/portal-site-editor/website-configure/components/category-edit/components/grid/AddPageActionCellRenderer.vue"
export default component.exports