var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-group",
    {
      attrs: { topic: _vm.$t("Page option"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c(
        "a-row",
        { attrs: { gutter: [32, 64] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 16 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Page title setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [0, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 7 } }, [
                    _vm._v(_vm._s(_vm.$t("Show page title")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 15 } },
                    [
                      _c("a-checkbox", {
                        staticClass: "mr-md",
                        attrs: { checked: _vm.showPageTitle },
                        on: { change: _vm.togglePageTitle }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [0, 16], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 7 } }, [
                    _vm._v(_vm._s(_vm.$t("Page title")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 15 } },
                    [
                      _c("a-input-search", {
                        attrs: {
                          placeholder: _vm.$t(
                            "You can input a custom page title for the page"
                          ),
                          disabled: !_vm.showPageTitle,
                          "enter-button": "OK"
                        },
                        on: { search: _vm.changePageTitle },
                        model: {
                          value: _vm.pageTitle,
                          callback: function($$v) {
                            _vm.pageTitle = $$v
                          },
                          expression: "pageTitle"
                        }
                      }),
                      _c(
                        "a-row",
                        {
                          staticStyle: { "margin-top": "8px" },
                          attrs: { gutter: [16, 0] }
                        },
                        [
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              },
                              attrs: { span: 12 }
                            },
                            [
                              _c("span", { staticClass: "mr-xs" }, [
                                _vm._v(_vm._s(_vm.$t("Text align")))
                              ]),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { value: _vm.pageTitleAlign },
                                  on: { change: _vm.changePageTitleAlign }
                                },
                                _vm._l(_vm.alignOptions, function(align) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: align.value,
                                      attrs: { value: align.value }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(align.label)) + " "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center"
                              },
                              attrs: { span: 12 }
                            },
                            [
                              _c("span", { staticClass: "mr-xs" }, [
                                _vm._v(_vm._s(_vm.$t("Font size")))
                              ]),
                              _c(
                                "a-select",
                                {
                                  staticStyle: { flex: "1" },
                                  attrs: { value: _vm.pageTitleFontSize },
                                  on: { change: _vm.changePageTitleFontSize }
                                },
                                _vm._l(_vm.pageTitleFontSizeOptions, function(
                                  item
                                ) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.value,
                                      attrs: { value: item.value }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t(item.label)) + " "
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Page logo setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8] } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show logo")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showLogo },
                        on: { change: _vm.toggleLogo }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Logo image")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("upload-photo", {
                        attrs: {
                          url: _vm.logoURL,
                          "folder-name-s3": _vm.folderNameS3
                        },
                        on: {
                          upload: _vm.changeLogoURL,
                          delete: _vm.deleteLogoURL
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c("a-col", { attrs: { span: 24 } }, [
                    _vm._v(_vm._s(_vm.$t("Logo caption")))
                  ])
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 0] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c("a-input-search", {
                        staticStyle: { width: "100%" },
                        attrs: { "enter-button": "OK", disabled: !_vm.logoURL },
                        on: { search: _vm.changeLogoCaption },
                        model: {
                          value: _vm.logoCaption,
                          callback: function($$v) {
                            _vm.logoCaption = $$v
                          },
                          expression: "logoCaption"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [32, 0] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Header and Footer setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Show header")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        staticStyle: { display: "block" },
                        attrs: { checked: _vm.showHeader },
                        on: { change: _vm.toggleHeader }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Show footer")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        staticStyle: { display: "block", margin: "0 0 5px 0" },
                        attrs: { checked: _vm.showFooter },
                        on: { change: _vm.toggleFooter }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("br"),
              _c("div", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Page icon setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 0], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Icon image (favicon)")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("upload-photo", {
                        attrs: {
                          url: _vm.faviconURL,
                          "folder-name-s3": _vm.folderNameS3
                        },
                        on: {
                          upload: _vm.changeFavicon,
                          delete: _vm.deleteFavicon
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Color setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Page title color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.pageTitleColor },
                        on: { change: _vm.changePageTitleColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Background color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.bgColor },
                        on: { change: _vm.changeBgColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Heading color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.headingColor },
                        on: { change: _vm.changeHeadingColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Item title color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.projectTitleColor },
                        on: { change: _vm.changeProjectTitleColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Item description color")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("picker-color", {
                        staticStyle: { width: "100%" },
                        attrs: { color: _vm.projectDesColor },
                        on: { change: _vm.changeProjectDesColor }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("p", { staticClass: "body font-bold mt-sm mb-none" }, [
                _vm._v(_vm._s(_vm.$t("Column setting")))
              ]),
              _c(
                "div",
                {
                  staticClass: "ml-xs",
                  staticStyle: { "font-size": "0.8rem", color: "#9b9b9b" }
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "When set flexibly, it adjusts the number of columns displayed according to the number of categories."
                        )
                      )
                    )
                  ])
                ]
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Type of column")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.columnSetting.type },
                          on: { change: _vm.changeColumnType }
                        },
                        _vm._l(_vm.typeOfColumnOptions, function(columnType) {
                          return _c(
                            "a-select-option",
                            {
                              key: columnType.value,
                              attrs: { value: columnType.value }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(columnType.label)) + " "
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.columnSetting.type === "fixed"
                ? _c(
                    "a-row",
                    {
                      attrs: { gutter: [16, 8], type: "flex", align: "middle" }
                    },
                    [
                      _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                        _vm._v(_vm._s(_vm.$t("Number of column")))
                      ]),
                      _c(
                        "a-col",
                        { attrs: { xs: 12, lg: 10 } },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { value: _vm.columnSetting.number },
                              on: { change: _vm.changeColumnNumber }
                            },
                            _vm._l(_vm.numberOfColumnOptions, function(
                              columnNumber
                            ) {
                              return _c(
                                "a-select-option",
                                {
                                  key: columnNumber.value,
                                  attrs: { value: columnNumber.value }
                                },
                                [_vm._v(" " + _vm._s(columnNumber.label) + " ")]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Page display setting")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Menu setting")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.menuType },
                          on: { change: _vm.changeMenuType }
                        },
                        [
                          _c(
                            "a-select-option",
                            { key: "icon", attrs: { value: "icon" } },
                            [_c("span", [_vm._v(_vm._s(_vm.$t("Icon view")))])]
                          ),
                          _c(
                            "a-select-option",
                            { key: "menu", attrs: { value: "menu" } },
                            [_vm._v(" " + _vm._s(_vm.$t("Menu view")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(" " + _vm._s(_vm.$t("Show share button")) + " ")
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showSharing },
                        on: { change: _vm.toggleSharing }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Page language")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.pageLang },
                          on: { change: _vm.changePageLang }
                        },
                        _vm._l(_vm.pageLangOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.id, attrs: { value: item.id } },
                            [_vm._v(" " + _vm._s(item.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8], type: "flex", align: "middle" } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Item order")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { value: _vm.createdOrder },
                          on: { change: _vm.changeCreatedOrder }
                        },
                        _vm._l(_vm.sortOptions, function(item) {
                          return _c(
                            "a-select-option",
                            { key: item.value, attrs: { value: item.value } },
                            [_vm._v(" " + _vm._s(_vm.$t(item.label)) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8] } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _c("div", [_vm._v(_vm._s(_vm.$t("Item naming")))]),
                    _c(
                      "div",
                      {
                        staticClass: "ml-xs",
                        staticStyle: { "font-size": "0.8rem", color: "#9b9b9b" }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("Examples: news, products, tickets ...")
                            ) +
                            " "
                        )
                      ]
                    )
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-input-search", {
                        staticStyle: { width: "100%" },
                        attrs: { "enter-button": "OK" },
                        on: { search: _vm.changeItemNaming },
                        model: {
                          value: _vm.itemNaming,
                          callback: function($$v) {
                            _vm.itemNaming = $$v
                          },
                          expression: "itemNaming"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [32, 16] } },
        [
          _c(
            "a-col",
            { attrs: { xs: 24, lg: 8 } },
            [
              _c("p", { staticClass: "body font-bold" }, [
                _vm._v(_vm._s(_vm.$t("Page introduction")))
              ]),
              _c(
                "a-row",
                { attrs: { gutter: [16, 8] } },
                [
                  _c("a-col", { attrs: { xs: 12, lg: 14 } }, [
                    _vm._v(_vm._s(_vm.$t("Show introduction button")))
                  ]),
                  _c(
                    "a-col",
                    { attrs: { xs: 12, lg: 10 } },
                    [
                      _c("a-checkbox", {
                        attrs: { checked: _vm.showIntroduction },
                        on: { change: _vm.toggleIntroduction }
                      }),
                      _vm.showIntroduction && !_vm.showPageIntroductionEditor
                        ? _c(
                            "a-button",
                            {
                              staticClass: "ml-xs",
                              attrs: { icon: "edit" },
                              on: {
                                click: function() {
                                  return (_vm.showPageIntroductionEditor = true)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Introduction content")) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.showIntroduction && _vm.showPageIntroductionEditor
        ? _c(
            "a-row",
            { attrs: { gutter: [0, 4] } },
            [
              _c(
                "a-col",
                { attrs: { xs: 24, lg: { span: 16, offset: 4 } } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "caption",
                      staticStyle: { color: "#f74a4a" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Please enter the description for this site")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("text-editor", {
                    attrs: {
                      projectId: "" + _vm.portalSite.id,
                      projectLang: _vm.pageLang,
                      content: _vm.introductionContent,
                      disabled: !_vm.showIntroduction
                    },
                    on: { change: _vm.changeIntroductionContent }
                  }),
                  _c(
                    "a-row",
                    {
                      staticClass: "mt-sm",
                      attrs: { type: "flex", justify: "end" }
                    },
                    [
                      _c(
                        "a-button",
                        {
                          staticClass: "mr-xs",
                          attrs: {
                            icon: "close-square",
                            type: "danger",
                            ghost: ""
                          },
                          on: {
                            click: function() {
                              return (_vm.showPageIntroductionEditor = false)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: { icon: "save", type: "primary" },
                          on: { click: _vm.updateIntroductionContent }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Save")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }