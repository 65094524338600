//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PickedUp from './components/PickedUp.vue';
import InternalTab from './components/InternalTab.vue';
import SiteForm from './components/SiteForm.vue';
export default {
  components: {
    PickedUp,
    InternalTab,
    SiteForm
  },

  props: {
    visible: { type: Boolean, default: false },
    portalSite: { type: Object, required: true, default: () => ({}) },
    category: { type: Object, required: true, default: () => ({}) },
    pages: { type: Array, required: true, default: () => [] }
  },

  data() {
    return {
      loading: false,
      activeTab: 'internal',
      pageEdit: {},
      externalTabMode: 'create',
      form: this.$form.createForm(this, { name: 'category_form' }),
      visibleSiteEdit: false
    };
  },

  computed: {
    formItemLayout() {
      return {
        labelCol: { xs: { span: 10 }, lg: { span: 4 } },
        wrapperCol: { xs: { span: 14 }, lg: { span: 20 } }
      };
    },
    formItemOptions() {
      return {
        categoryName: {
          initialValue: this.category.name || '',
          rules: [{ required: true, message: this.$t('Please input your category name!') }]
        }
      };
    }
  },

  methods: {
    editPage(page) {
      this.pageEdit = { ...page };
      this.visibleSiteEdit = true;
    },
    updateCategory() {
      this.form.validateFieldsAndScroll(async (error, values) => {
        if (!error) {
          try {
            this.loading = true;
            const data = await this.$s.api.page.updateCategoryByPortalPageId({
              pageId: this.portalSite.id,
              categoryId: this.category.id,
              payload: values
            });
            this.form.resetFields();
            this.$emit('updateCategory', data);
            this.$notification.success({
              message: this.$t('This category has been updated!')
            });
          } catch (error) {
            console.log(error);
            this.$notification.error({
              message: this.$t('Updating category failed!')
            });
          } finally {
            this.loading = false;
          }
        }
      });
    },
    updatePage(data) {
      this.$emit('updatePage', data);
      // ToDo: Reset pageEdit
      this.pageEdit = {};
      this.visibleSiteEdit = false;
    },
    loadPages() {
      this.$emit('loadPages');
    }
  }
};
