var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section-group",
    {
      attrs: { topic: _vm.$t("Publish info"), expanded: _vm.expanded },
      on: { toggleExpanded: _vm.toggleExpanded }
    },
    [
      _c("div", { staticClass: "important-note mb-xs" }, [
        _vm._v(
          " " + _vm._s(_vm.$t("*This is the URL of the public version")) + " "
        )
      ]),
      _c("div", { staticClass: "font-bold mb-xs" }, [
        _vm._v(_vm._s(_vm.$t("Publish URL setting")))
      ]),
      _c(
        "a-row",
        {
          attrs: {
            gutter: [16, 16],
            type: "flex",
            justify: "space-between",
            align: "middle"
          }
        },
        [
          _c("a-col", { attrs: { md: 24, lg: 12 } }, [
            _c(
              "div",
              { staticClass: "content-start" },
              [
                _c(
                  "a-input-search",
                  {
                    staticStyle: { flex: "1" },
                    attrs: {
                      size: "large",
                      disabled: _vm.isPrimary,
                      "enter-button": "OK"
                    },
                    on: {
                      search: _vm.changeSlug,
                      change: _vm.handleOnChangeSlug
                    },
                    model: {
                      value: _vm.newSlug,
                      callback: function($$v) {
                        _vm.newSlug = $$v
                      },
                      expression: "newSlug"
                    }
                  },
                  [
                    _c(
                      "template",
                      { slot: "addonBefore" },
                      [
                        _c(
                          "a-tooltip",
                          {
                            attrs: {
                              placement: "topLeft",
                              title: _vm.$t("Go to edit sub domain"),
                              "arrow-point-at-center": ""
                            }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  "font-weight": "bold"
                                },
                                on: { click: _vm.goToEditProject }
                              },
                              [_vm._v(" " + _vm._s(_vm.baseUrl + "/") + " ")]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ]),
          _c(
            "a-col",
            { attrs: { md: 24, lg: 12 } },
            [
              _c(
                "a-button",
                {
                  staticClass: "mr-xs",
                  attrs: { size: "large", type: "primary" },
                  on: { click: _vm.copyPublishURL }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Copy")) + " ")]
              ),
              _c(
                "a-button",
                {
                  attrs: { size: "large", disabled: !_vm.isPublic },
                  on: { click: _vm.openPortalSite }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Open")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }