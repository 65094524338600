//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PAGE_STATE } from '@/enum';
import { mapActions, mapGetters } from 'vuex';
import Suggestion from './Suggestion.vue';
import SearchBox from '@/views/project/components/SearchBox';

export default {
  components: { Suggestion, SearchBox },

  props: {
    category: { type: Object, default: () => ({}) },
    pages: { type: Array, default: () => [] },
    portalSite: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      selectedPages: {},
      project: {
        data: [],
        loading: false,
        search: {
          title: '',
          sub_domain: ''
        },
        pagination: {
          pageSize: 20,
          current: 1,
          total: 0
        }
      },
      page: {
        data: [],
        loading: false,
        search: { title: '', slug: '' },
        pagination: { pageSize: 20, current: 1, total: 0 }
      }
    };
  },

  created() {
    this.initData();
  },

  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('project', ['projectById']),
    companyId() {
      return this.userInfo.company_id;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    selectedUnPublicPages() {
      return Object.keys(this.selectedPages)
        .filter(key => !this.selectedPages[key].ispublic)
        .map(key => this.selectedPages[key]);
    },
    projectFilter() {
      const { search, pagination } = this.project;
      const { title, sub_domain } = search;
      return {
        where: {
          status: 'ACTIVE',
          ...(title && { name: { ilike: `%${title}%` } }),
          ...(sub_domain && { sub_domain: { ilike: `%${sub_domain}%` } })
        },
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        order: 'id DESC'
      };
    }
  },
  watch: {},
  methods: {
    ...mapActions('project', ['getProjectById']),
    async initData() {
      try {
        this.project.data = await this.handleGetProjects();
        if (this.project.pagination.total === 0) {
          const count = await this.$s.api.project.count(this.companyId, this.projectFilter.where);
          this.project.pagination.total = count?.count;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async handleLoadMoreProjects({ current = null }) {
      this.project.pagination.current = current || this.project.pagination.current + 1;
      const moreProjects = await this.handleGetProjects();
      if (current) {
        this.project.data = moreProjects;
      } else this.project.data = [...this.project.data, ...moreProjects];
    },
    async handleGetProjects() {
      this.project.loading = true;
      const projects = await this.$s.api.project.find(this.companyId, {
        filter: this.projectFilter
      });
      this.project.loading = false;
      return projects;
    },
    getPublishUrlByPage(page) {
      const baseDomain = process.env.VUE_APP_UNIVOICE_SUBDOMAIN;
      const project = this.projectById(page.campaign_id) || {};
      const { sub_domain } = project;
      const slug = page.state === PAGE_STATE.PRIMARY ? '' : page.slug || page.id;
      return `https://${sub_domain}${baseDomain}/${slug}`;
    },
    async handleAddPage(page) {
      this.page.loading = true;
      this.selectedPages[page.id] = page;
      await this.addPages();
      this.page.data = this.page.data.filter(item => item.id !== page.id);
      this.page.loading = false;
      this.selectedPages = {};
    },
    async addPages() {
      try {
        for (let i = 0; i < Object.keys(this.selectedPages).length; i++) {
          const key = Object.keys(this.selectedPages)[i];
          this.selectedPages[key].url = this.getPublishUrlByPage(this.selectedPages[key]);
        }
        const payload = Object.values(this.selectedPages).map(item => ({
          project_id: item.id,
          name: item.name,
          url: item.url,
          description: item.description,
          thumbnail: item.thumbnail
        }));
        const data = await this.$s.api.pageGroup.createPageByCategoryId({
          id: this.category.id,
          payload
        });
        this.$emit('addPages', {
          categoryId: this.category.id,
          data: data.map(item => {
            return {
              ...item,
              project: this.selectedPages[item.project_id]
            };
          })
        });
        // ToDo: Reset data
        this.selectedPages = {};
        this.$notification.success({
          message: this.$t('The sites have been added!')
        });
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: this.$t('Adding sites failed!')
        });
      }
    },

    handleSearchProject() {
      this.project.pagination.current = 1;
      this.handleLoadMoreProjects({ current: 1 });
    },
    handleResetProject() {
      this.project.search = {
        title: '',
        sub_domain: ''
      };
      this.project.pagination.current = 1;
      this.handleLoadMoreProjects({ current: 1 });
    },
    getPageFilter() {
      const { search, pagination } = this.page;
      const { title, slug } = search;
      // Some pages may not have project_id, so we need to filter them out
      const ninIds = this.pages.filter(item => item.project_id).map(item => item.project_id);
      return {
        where: {
          status: 'ACTIVE',

          ...(title && { name: { ilike: `%${title}%` } }),
          ...(slug && { or: [{ slug: { ilike: `%${slug}%` } }, { id: parseInt(slug) }] }),
          id: {
            nin: ninIds
          }
        },
        fields: [
          'id',
          'name',
          'ispublic',
          'description',
          'thumbnail',
          'template',
          'slug',
          'state',
          'created',
          'campaign_id',
          'company_id'
        ],
        limit: pagination.pageSize,
        skip: (pagination.current - 1) * pagination.pageSize,
        order: 'id DESC'
      };
    },
    async handleGetPageByProjectId(projectId, agPagination) {
      this.page.loading = true;
      if (agPagination) {
        const { page, pageSize } = agPagination;
        this.page.pagination = { ...this.page.pagination, current: page, pageSize };
      }
      const filter = this.getPageFilter();
      const [pages, count] = await Promise.all([
        this.$s.api.page.find(projectId, { filter }),
        this.$s.api.page.count(projectId, filter.where)
      ]);
      this.page.data = pages;
      this.page.pagination.total = count?.count;
      this.page.loading = false;
    }
  }
};
