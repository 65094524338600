//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SectionGroup from '@/components/PortalSite/SectionGroup.vue';
import PickerColor from '@/components/tools/PickerColor.vue';

export default {
  components: { SectionGroup, PickerColor },

  props: { settingOptions: { type: Object, require: true, default: () => ({}) } },

  data() {
    return {
      categorySearchTitle: '',
      expanded: this.$ls.get('uv-exp-search-section') ?? false,
      searchOptions: [
        { value: "global", label: this.$t('Global menu') },
        { value: "mega", label: this.$t('Mega menu') },
        { value: "leaf", label: this.$t('Scroll down select') },
      ],
    };
  },

  watch: {
    settingOptions: {
      handler() {
        this.initData(this.settingOptions);
      },
      immediate: true
    }
  },

  computed: {
    // ToDo: Search setting
    searchSetting() {
      return this.settingOptions?.search_setting || {};
    },
    searchBy() {
      return this.searchSetting?.search_by || 'mega';
    },
    showKeywordSearch() {
      return this.searchSetting?.show_keyword_search ?? true;
    },
    showCategorySearch() {
      return this.searchSetting?.show_category_search ?? true;
    },
    showNumbering() {
      return this.searchSetting?.show_numbering ?? true;
    },

    // ToDo: Mega setting
    megaSetting() {
      return this.settingOptions?.mega_setting || {};
    },
    searchTitleColor() {
      return this.megaSetting?.search_title_color || '#4a86e8';
    },
    rootBgColor() {
      return this.megaSetting?.root_bg_color || '#4a86e8';
    },
    rootFontColor() {
      return this.megaSetting?.root_font_color || '#fff';
    },
    subBgColor() {
      return this.megaSetting?.sub_bg_color || '#c9daf8';
    },
    subFontColor() {
      return this.megaSetting?.sub_font_color || '#fff';
    }
  },

  methods: {
    initData(data) {
      this.categorySearchTitle = data?.search_setting?.category_search_title || '';
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
      this.$ls.set('uv-exp-search-section', this.expanded);
    },

    // ToDo: Change search setting
    changeSearchSetting(value) {
      this.updateSettingOptions({
        search_setting: { ...this.searchSetting, ...value }
      });
    },
    toggleShowKeywordSearch({ target }) {
      this.changeSearchSetting({ show_keyword_search: target.checked });
    },
    toggleShowCategorySearch({ target }) {
      this.changeSearchSetting({ show_category_search: target.checked });
    },
    changeSearchBy(value) {
      this.changeSearchSetting({ search_by: value });
    },
    changeCategorySearchTitle(value) {
      this.changeSearchSetting({ category_search_title: value });
    },
    toggleNumbering({ target }) {
      this.changeSearchSetting({ show_numbering: target.checked });
    },

    // ToDo: Chang mega setting
    changeMegaSetting(value) {
      this.updateSettingOptions({
        mega_setting: { ...this.megaSetting, ...value }
      });
    },
    changeSearchTitleColor(value) {
      this.changeMegaSetting({ search_title_color: value });
    },
    changeRootBgColor(value) {
      this.changeMegaSetting({ root_bg_color: value });
    },
    changeRootFontColor(value) {
      this.changeMegaSetting({ root_font_color: value });
    },
    changeSubBgColor(value) {
      this.changeMegaSetting({ sub_bg_color: value });
    },
    changeSubFontColor(value) {
      this.changeMegaSetting({ sub_font_color: value });
    },

    // ToDo: Update setting options
    updateSettingOptions(value) {
      this.$emit('update-setting-options', value);
    }
  }
};
